import React from "react";
import Seo from "../components/SEO/SEO";
import Container from "../components/Container/Container";
import Helmet from "react-helmet";

import { Link } from "gatsby";

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <Helmet
      bodyAttributes={{
        class: "no-banner",
      }}
    />
    <Container>
      <div className="border-b border-grey-default box-border min-h-[70vh]">
        <div className="container flex md:grid md:grid-cols-12 mt-[88px]">
          <div className="md:col-span-9 xl:col-span-7 pb-10 md:pb-[120px]">
            <article className="copy md:pr-10">
              <h1 className="font-serif text-[32px] leading-[1.2]">Not found</h1>
              <div className="copy copy--container lg:max-w-[80%]">
                <p>
                  Sorry, we can’t seem to find that page.{" "}
                  <Link to="/">Click here</Link> to return home
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </Container>
  </>
);

export default NotFoundPage;
